/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, keywords, title, imageProp, urlProp }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const url = urlProp || site.siteMetadata.siteUrl;
  const image = !imageProp
    ? `${site.siteMetadata.siteUrl}`
    : imageProp.includes("firebase")
    ? `${imageProp}`
    : `${site.siteMetadata.siteUrl}${imageProp}`;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          prefix: "og: http://ogp.me/ns#",
          content: title
        },
        {
          property: `og:description`,
          prefix: "og: http://ogp.me/ns#",
          content: metaDescription
        },
        {
          property: `og:type`,
          prefix: "og: http://ogp.me/ns#",
          content: `blog`
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          property: `og:image`,
          prefix: "og: http://ogp.me/ns#",
          content: image
        },
        {
          property: `twitter:image`,
          content: image
        },
        {
          property: `image`,
          content: image
        },
        {
          property: `og:url`,
          prefix: "og: http://ogp.me/ns#",
          content: url
        },
        {
          property: `og:image:secure_url`,
          prefix: "og: http://ogp.me/ns#",
          content: image
        }
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `)
              }
            : []
        )
        .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default SEO;
